import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SidePanel {
    name: string | null;
    isOpen: boolean;
}

interface SidePanelState {
    sidePanel: Array<SidePanel>;
}

const initialState: SidePanelState = {
    sidePanel: [],
};

/*
    The idea of this state is to keep track of the side panels that are open and closed.
    This way we can have multiple side panels open at the same time.
    This does not handle opening and closing the side panels, that is done in the component, we
    only keep track of the state of the side panels, as we need to move content around when a side panel is open.

    Example of the state:
    SidePanelState: {
        sidePanel: [
            {
                name: 'sidePanel1',
                isOpen: true,
            },
            {
                name: 'sidePanel2',
                isOpen: false,
            },
        ]
    }
*/
export const sidePanelSlice = createSlice({
    name: 'sidePanelSlice',
    initialState,
    reducers: {
        openSidePanel: (state, action: PayloadAction<string>) => {
            const sidePanel = state.sidePanel.find((panel) => panel.name === action.payload);
            if (sidePanel) {
                sidePanel.isOpen = true;
            } else {
                state.sidePanel.push({
                    name: action.payload,
                    isOpen: true,
                });
            }
        },
        closeSidePanel: (state, action: PayloadAction<string>) => {
            const sidePanel = state.sidePanel.find((panel) => panel.name === action.payload);
            if (sidePanel) {
                sidePanel.isOpen = false;
            } else {
                state.sidePanel.push({
                    name: action.payload,
                    isOpen: false,
                });
            }
        },
    },
});

export const { openSidePanel, closeSidePanel } = sidePanelSlice.actions;
export const selectSidePanel = (state: { sidePanel: SidePanelState }) => state.sidePanel.sidePanel;
export const sidePanelReducer = sidePanelSlice.reducer;

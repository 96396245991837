import React from 'react';
import { useGetCurrentTenant } from '../api/getCurrentTenant';
import { useGetCurrentUser } from '../api/getCurrentUser';
import AuthContext from '../context/AuthContext';

export default function AuthProvider({ children }: { children: React.ReactNode }) {
    const currentUser = useGetCurrentUser({});
    const currentTenant = useGetCurrentTenant({});
    const permissions = React.useMemo(() => {
        if (currentUser && currentUser?.data) {
            return currentUser.data.permissions;
        }
        return [];
    }, [currentUser]);

    const value = React.useMemo(() => ({
        initialised: typeof currentUser.data !== 'undefined' && typeof currentTenant.data !== 'undefined',
        currentUser: currentUser.data || null,
        currentTenant: currentTenant.data || null,
        permissions,
    }), [currentUser.data, currentTenant.data, permissions]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}

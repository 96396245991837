// @flow

export default class ApiClientError extends Error {
    code: string;

    message: string;

    constructor(code: string, message: string) {
        super(message);

        this.code = code;
        this.message = code;
    }

    toString(): string {
        return `${this.code} - ${this.message}`;
    }
}

export const ERR_INVALID_EVENT = 'ERR_INVALID_EVENT';
export const ERR_AUTH_BACKEND_NOT_INITIALIZED = 'ERR_AUTH_BACKEND_NOT_INITIALIZED';

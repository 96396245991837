// @flow

import type { AuthBackend, AuthSettings } from 'speed-js-core/src/api/auth/AuthBackend';
import type { AuthStorage } from 'speed-js-core/src/api/auth/AuthStorage';
import type ApiClient from 'speed-js-core/src/api/ApiClient';

export default class JWTBackend implements AuthBackend {
    ACCESS_TOKEN_REFRESH_LIFETIME: number = 10 * 60 * 1000; // 10 Minutes

    removeRefreshTokenOnFailure: boolean = true;

    client: ApiClient;

    storage: AuthStorage;

    accessToken: ?string;

    refreshToken: ?string;

    authResourceName: string;

    accessTokenValidUntil: null | number = null;

    _refreshTokenPromise: Promise<string | null> | null = null;

    constructor(client: ApiClient, settings: AuthSettings) {
        const {
            AuthStorageClass, authResourceName, ACCESS_TOKEN_REFRESH_LIFETIME, removeRefreshTokenOnFailure,
        } = settings;

        this.client = client;
        this.storage = new AuthStorageClass(client);
        this.authResourceName = authResourceName;
        if (ACCESS_TOKEN_REFRESH_LIFETIME) {
            this.ACCESS_TOKEN_REFRESH_LIFETIME = ACCESS_TOKEN_REFRESH_LIFETIME;
        }
        if (removeRefreshTokenOnFailure != null) {
            this.removeRefreshTokenOnFailure = removeRefreshTokenOnFailure;
        }
    }

    init(): Promise<[boolean, ?Object]> {
        return this.getInitialToken().then(() => [!!this.accessToken, undefined]);
    }

    getInitialToken: () => Promise<any> = (): Promise<any> => (
        new Promise<string | null>((resolve) => {
            this.storage.getToken().then((token) => {
                this.refreshToken = token;
                this.refreshTokens().then(resolve);
            });
        })
    );

    async getHeaders(): Object {
        if (!this.refreshToken) return {};

        // $FlowIgnore wtf flow, there is a check if accessTokenValidUntil has a value
        if (this.accessTokenValidUntil && new Date().getTime() > this.accessTokenValidUntil) {
            await this.refreshTokens();
        }

        // Check again if it was refreshed correctly
        if (this.accessToken) {
            return {
                Authorization: `JWT ${this.accessToken}`,
            };
        }
        return {};
    }

    authorize(data: { username: string, password: string }): Promise<Object> {
        return this.client.resources[this.authResourceName].login(data).then((response) => {
            const { data: { token } } = response;

            this.setToken(token);

            return response;
        });
    }

    deauthorize(): Promise<boolean> {
        return this.client.resources[this.authResourceName].logout().then(() => {
            this.storage.removeToken().then(() => {
                this.resetState();
            });
        });
    }

    setRefreshToken(token: string, errCallback?: (error?: Error) => void) {
        this.refreshToken = token;
        // $FlowIgnore
        this.storage.setToken(token, errCallback);
    }

    refreshTokens(): Promise<string | null> {
        if (this.refreshToken) {
            if (this._refreshTokenPromise) {
                return this._refreshTokenPromise;
            }

            this._refreshTokenPromise = this.client.resources[this.authResourceName]
                .refreshToken({ refresh: this.refreshToken })
                .then((token) => {
                    this.setToken(token);
                    this._refreshTokenPromise = null;
                    return token.access;
                }).catch((e) => {
                    this._refreshTokenPromise = null;

                    if (this.removeRefreshTokenOnFailure) {
                        this.refreshToken = null;
                        this.storage.removeToken();
                        this.accessToken = null;
                        this.accessTokenValidUntil = null;
                        return null;
                    }

                    throw e;
                });

            return this._refreshTokenPromise;
        }
        return Promise.resolve(null);
    }

    setToken(token: { access: string, refresh: string }) {
        this.accessTokenValidUntil = (new Date()).getTime() + this.ACCESS_TOKEN_REFRESH_LIFETIME;
        if (token.refresh) {
            this.setRefreshToken(token.refresh);
        }
        this.accessToken = token.access;
    }

    resetState() {
        this.accessToken = null;
        this.refreshToken = null;
        this.accessTokenValidUntil = null;
        this._refreshTokenPromise = null;
    }
}

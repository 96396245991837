import { WebsocketAuthBackend } from 'speed-js-core/src/websocket/auth/WebsocketAuthBackend';
import type WebSocketClient from 'speed-js-core/src/websocket/WebSocketClient';

export type AuthData = { userId: string | null, sessionId: string | null };

const AUTH_MESSAGE = 'connect';

class SessionBackend implements WebsocketAuthBackend {
    _client?: WebSocketClient;

    authData?: AuthData;

    constructor(authData?: AuthData) {
        this.authData = authData;
    }

    authorize(): Promise<undefined> {
        return new Promise((resolve) => {
            if (!this._client || !this.authData) {
                resolve(undefined);
                return;
            }

            this._client.send(AUTH_MESSAGE, {
                user_id: this.authData.userId,
                session_id: this.authData.sessionId,
            });
            resolve(undefined);
        });
    }

    setClient(client: WebSocketClient) {
        this._client = client;
    }
}

export default SessionBackend;

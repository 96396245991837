// @flow

import ReconnectingWebSocket from 'reconnecting-websocket';
import { difference, remove } from 'lodash';
import uuid4 from 'uuid/v4';
import type { WebsocketAuthBackend } from './auth/WebsocketAuthBackend';


export const SUBSCRIBE_MESSAGE: string = 'subscribe';
export const PING_MESSAGE: string = 'ping';
export const PONG_MESSAGE: string = 'pong';
export const ERROR_MESSAGE: string = 'err';
export const AUTH_MESSAGE: string = 'authenticate';


export default class WebSocketClient {
    _address: string;

    _options: Object;

    _subscriptions = {};

    _queuedSubscriptions = {};

    _queuedUnsubscriptions = [];

    _onOpenCallbacks = [];

    _subscribingGroups = [];

    _unsubscribingGroups = [];

    _onCloseCallbacks = [];

    _onSubscribeCallbacks = {};

    _previousSubscriptions = {};

    client: ReconnectingWebSocket | WebSocket;

    _opened: boolean = false;

    _pingInterval: ?IntervalID = null;

    _pingId: number = 0;

    _pingCloseTimeout: ?TimeoutID = null;

    _auth: ?WebsocketAuthBackend;

    _sessionStorage: ?Storage;

    connectionId: string;

    constructor(
        address: string,
        authBackend?: WebsocketAuthBackend,
        sessionStorage?: Storage,
        options: Object = {
            minReconnectionDelay: 1,
            maxReconnectionDelay: 5000,
            maxRetries: Infinity,
        },
    ) {
        this._auth = authBackend;
        if (this._auth) {
            this._auth.setClient(this);
        }

        this._sessionStorage = sessionStorage;
        this._options = options;

        this.connectionId = this._getConnectionId();

        const url = new URL(address);
        url.searchParams.set('connection', this.connectionId);

        this._address = url.toString();

        this._initClient();
    }

    _initClient = () => {
        this.client = new ReconnectingWebSocket(this._address, '', this._options);

        this.client.addEventListener('open', this._handleOpen);
        this.client.addEventListener('message', this._handleMessage);
        this.client.addEventListener('close', this._handleClose);

        if (this.client.readyState === ReconnectingWebSocket.OPEN && !this._opened) {
            this._handleOpen();
        }
    };

    _getConnectionId = () => {
        if (this._sessionStorage) {
            try {
                let connId = this._sessionStorage.getItem('wsConnectionId');
                // Check if the tab has been opened by another tab and create a new connection id in this case
                // as it might inherit the connection id of the opener
                if (typeof window !== 'undefined' && window.opener && !this._sessionStorage.getItem('preventRefresh')) {
                    connId = uuid4();
                    this._sessionStorage.setItem('wsConnectionId', connId);

                    // Add a flag to the sessionStorage which will prevent from generating a new connectionId
                    // if the tab is refreshed (as the window.opener property is preserved on refresh)
                    this._sessionStorage.setItem('preventRefresh', connId);
                    return connId;
                }
                if (connId) {
                    return connId;
                }
                connId = uuid4();
                // $FlowFixMe
                this._sessionStorage.setItem('wsConnectionId', connId);
                return connId;
            } catch (e) {
                // handle private browser mode dom exception
                return uuid4();
            }
        } else {
            return uuid4();
        }
    };

    _handleOpen = async () => {
        if (this._auth) {
            await this._auth.authorize();
        }

        this._opened = true;

        Object.keys(this._previousSubscriptions).forEach((key) => {
            const subs = this._previousSubscriptions[key];

            subs.forEach((sub) => {
                this.subscribe({
                    [key]: sub,
                });
            });
        });
        this._previousSubscriptions = {};

        this._processSubscriptionQueue();

        if (!this._pingInterval) {
            this._pingInterval = setInterval(() => {
                // Missed heartbeat (probably due to lost connection) try to reconnect
                this._pingCloseTimeout = setTimeout(() => {
                    this.reconnect();
                }, 5000);
                this.send(PING_MESSAGE, { id: this._pingId = Date.now() });
            }, 10000);
        }

        this._onOpenCallbacks.forEach((callback) => {
            callback(this);
        });
    };

    _handleClose = () => {
        this._opened = false;
        Object.keys(this._subscriptions).forEach((key) => {
            let subs = this._subscriptions[key];

            if (typeof this._previousSubscriptions[key] !== 'undefined') {
                subs = [
                    ...this._previousSubscriptions[key],
                    ...subs,
                ];
            }

            this._previousSubscriptions[key] = subs;
        });
        this._subscriptions = {};
        this._queuedSubscriptions = {};

        if (this._pingInterval) {
            clearInterval(this._pingInterval);
        }
        this._pingInterval = null;

        this._onCloseCallbacks.forEach((callback) => {
            callback(this);
        });
    };

    // Handle the message received based on the msg type (subscribe, ping, pong, error, authenticate)
    _handleMessage = (event: MessageEvent | Event) => {
        if (typeof event.data !== 'string') {
            return;
        }

        const { msg, ...data } = JSON.parse(event.data);

        switch (msg) {
        case SUBSCRIBE_MESSAGE:
            this.handleSubscribe(data);
            break;
        case PONG_MESSAGE:
            this.handlePong(data);
            break;
        case ERROR_MESSAGE:
            console.error(`${data.code} - ${data.info}`);
            break;
        default:
            if (typeof this._subscriptions[msg] !== 'undefined') {
                this._subscriptions[msg].forEach((sub) => sub(data));
            }
        }
    };

    send = (message: string, data: Object) => {
        this.client.send(JSON.stringify({
            msg: message, ...data,
        }));
    };

    _processSubscriptionQueue = () => {
        const groups = Object.keys(this._queuedSubscriptions);
        const newGroups = difference(groups, this._subscribingGroups);
        if (newGroups.length) {
            this._subscribingGroups = this._subscribingGroups.concat(newGroups);
            this.send(SUBSCRIBE_MESSAGE, { groups: newGroups, action: 'join' });
        }
    };

    _processUnsubscriptionQueue = () => {
        const newGroups = difference(this._queuedUnsubscriptions, this._unsubscribingGroups);
        if (newGroups.length) {
            this._unsubscribingGroups = this._unsubscribingGroups.concat(newGroups);
            this.send(SUBSCRIBE_MESSAGE, { groups: newGroups, action: 'leave' });
        }
    }

    // Handle the reception of a message 'subscribe'
    handleSubscribe = (data: Object) => {
        const { groups, action } = data;

        if (action === 'join') {
            // Join action
            groups.forEach((group: string) => {
                if (typeof this._subscriptions[group] !== 'undefined') {
                    // Update an existing subscription
                    this._subscriptions[group] = [...this._subscriptions[group], ...this._queuedSubscriptions[group]];
                } else {
                    // Add a new subscription
                    this._subscriptions[group] = this._queuedSubscriptions[group];
                }
                // Remove the queued subscription
                delete this._queuedSubscriptions[group];

                if (typeof this._onSubscribeCallbacks[group] !== 'undefined') {
                    // Call the on subscribe callbacks for the newly joined group
                    this._onSubscribeCallbacks[group].forEach((callback) => {
                        callback(this);
                    });
                }
            });

            // Remove the newly subscribed groups from the subscribing groups array
            remove(this._subscribingGroups, (g) => groups.indexOf(g) > -1);
        } else {
            // Leave action
            groups.forEach((group: string) => {
                if (typeof this._subscriptions[group] !== 'undefined') {
                    // Remove subscription
                    delete this._subscriptions[group];

                    if (typeof this._onSubscribeCallbacks[group] !== 'undefined') {
                        // Remove on subscribe callbacks for the group that has been left
                        delete this._onSubscribeCallbacks[group];
                    }
                }

                // Remove the queued unsubscription
                remove(this._queuedUnsubscriptions, (g) => g === group);
            });

            // Remove the groups that left from the unsubscribing groups array
            remove(this._unsubscribingGroups, (g) => groups.indexOf(g) > -1);
        }
    };

    // Handle the reception of a message 'pong'
    handlePong = (data: Object) => {
        if (this._pingCloseTimeout) {
            clearTimeout(this._pingCloseTimeout);
            this._pingCloseTimeout = null;
        }
        if (data.id !== this._pingId) {
            console.error(`Got unexpected response: ${JSON.stringify(data)}`);
        }
    };

    subscribe(groups: { [group: string]: (data: any) => any }) {
        Object.keys(groups).forEach((name) => {
            if (typeof this._queuedSubscriptions[name] !== 'undefined') {
                // Existing queued subscription , add a new callback
                if (this._queuedSubscriptions[name].indexOf(groups[name]) === -1) {
                    this._queuedSubscriptions[name].push(groups[name]);
                }
            } else {
                // Add a new queued subscription
                this._queuedSubscriptions[name] = [groups[name]];
            }
        });

        if (this._opened) {
            this._processSubscriptionQueue();
        }
    }

    unSubscribe(groups: Array<string> | {[group: string]: (data: any) => any }) {
        if (!this._opened) return;

        if (Array.isArray(groups)) {
            // groups is an array of strings
            groups.forEach((name) => {
                if (this._queuedUnsubscriptions.indexOf(name) === -1) {
                    // Append new leaving group to queuedUnsubscriptions
                    this._queuedUnsubscriptions.push(name);
                }
            });
        } else {
            // groups is an object with [groupName, callback] pairs
            Object.keys(groups).forEach((name) => {
                if (typeof this._subscriptions[name] !== 'undefined' && this._subscriptions[name].length > 1) {
                    // Have an existing subscription for this group with more than one callbacks
                    // remove only the callback provided in the data
                    const callback = groups[name];
                    this._subscriptions[name] = this._subscriptions[name].filter((sc) => (
                        sc !== callback
                    ));
                } else if (this._queuedUnsubscriptions.indexOf(name) === -1) {
                    // Append new leaving group to queuedUnsubscriptions
                    this._queuedUnsubscriptions.push(name);
                }
            });
        }

        this._processUnsubscriptionQueue();
    }

    reconnect() {
        if (typeof this.client.reconnect !== 'undefined') {
            // $FlowFixMe
            this.client.reconnect();
        } else {
            this.client.close();
            this._initClient();
        }
    }

    onOpen(callback: Function) {
        this._onOpenCallbacks.push(callback);

        if (this._opened) {
            callback(this);
        }
    }

    onClose(callback: Function) {
        this._onCloseCallbacks.push(callback);
    }

    removeOnOpen(callback: Function) {
        this._onOpenCallbacks.splice(this._onOpenCallbacks.indexOf(callback), 1);
    }

    removeOnClose(callback: Function) {
        this._onCloseCallbacks.splice(this._onCloseCallbacks.indexOf(callback), 1);
    }

    onSubscribe(group: string, callback: Function) {
        if (typeof this._onSubscribeCallbacks[group] === 'undefined') {
            this._onSubscribeCallbacks[group] = [];
        }
        this._onSubscribeCallbacks[group].push(callback);
    }
}

// @flow

import type { AuthBackend } from 'speed-js-core/src/api/auth/AuthBackend';
import type ApiClient from 'speed-js-core/src/api/ApiClient';

export default class NoopBackend implements AuthBackend {
    client: ApiClient;

    constructor(client: ApiClient) {
        this.client = client;
    }

    init(): Promise<[boolean, ?Object]> {
        return Promise.resolve([true, undefined]);
    }

    async getHeaders(): Object {
        return {};
    }

    authorize(data: { username: string, password: string }): Promise<Object> {
        return Promise.resolve({});
    }

    deauthorize(): Promise<boolean> {
        return Promise.resolve(true);
    }
}

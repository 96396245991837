// @flow

import type ApiClient from 'speed-js-core/src/api/ApiClient';

export default class NestedResource {
    isNested = true;

    client: ApiClient;

    basePath: string;

    objectPath: string;

    nestedResources: { [string]: Class<NestedResource> };

    constructor(client: ApiClient) {
        this.client = client;
    }

    setNestedResources() {
        if (this.nestedResources) {
            Object.keys(this.nestedResources).forEach((sr) => {
                // $FlowFixMe
                this[sr] = new this.nestedResources[sr](this.client);
                // $FlowFixMe
                this[sr].setNestedResources();
            });
        }
    }

    _getBasePath = (parentId: string) => this.basePath.replace('{parent}', parentId);

    _getObjectPath = (parentId: string, id: string) => (
        this.objectPath.replace('{ident}', id).replace('{parent}', parentId)
    );

    // TODO: Validate object using the API schema or other resource props?

    schema(parentId: string, query: Object = {}): Promise<Object> {
        return this.client._request(this._getBasePath(parentId), 'OPTIONS', query).then((response) => response.data);
    }

    list(parentId: string, query: Object): Promise<Object> {
        return this.client._request(this._getBasePath(parentId), 'GET', query).then((response) => response.data);
    }

    get(parentId: string, id: string, query: Object): Promise<Object> {
        return this.client._request(this._getObjectPath(parentId, id), 'GET', query).then((response) => response.data);
    }

    create(parentId: string, data: Object): Promise<Object> {
        return this.client._request(this._getBasePath(parentId), 'POST', data).then((response) => response.data);
    }

    update(parentId: string, id: string, data: Object): Promise<Object> {
        return this.client._request(this._getObjectPath(parentId, id), 'PUT', data).then((response) => response.data);
    }

    partialUpdate(parentId: string, id: string, data: Object): Promise<Object> {
        return this.client._request(this._getObjectPath(parentId, id), 'PATCH', data).then((response) => response.data);
    }

    delete(parentId: string, id: string): Promise<Object> {
        return this.client._request(this._getObjectPath(parentId, id), 'DELETE').then((response) => response.data);
    }
}

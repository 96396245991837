import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { Store } from 'redux';
import { Provider as ReduxProvider } from 'react-redux';
import ApiClient from 'speed-js-core/src/api/ApiClient';
import type WebSocketClient from 'speed-js-core/src/websocket/WebSocketClient';
import { WSContext } from 'speed-js-react/src/websocket';
import UnregisteredComponentError from './errors/UnregisteredComponentError';

export type ComponentImporterFunction = () => Promise<{ default: React.ComponentType<any> }>;

class AppRegistry<APIResources> {
    protected reduxStore: Store | null = null;

    protected wsClient: WebSocketClient | null = null;

    protected loadingIndicator: React.ReactNode = null;

    protected apiClient: ApiClient<APIResources> | null = null;

    protected otherProviders: ((result: React.ReactNode) => React.ReactNode)[] = [];

    protected ContextProvider: React.Provider<ApiClient<APIResources> | null> | null = null;

    protected i18n: {
        locale: string,
        messages: { [id: string]: string }
    } | null = null;

    protected registeredAppComponents: {
        [componentName: string]: ComponentImporterFunction,
    } = {};

    constructor(
        wsClient?: WebSocketClient,
        reduxStore?: Store,
        apiClient?: {
            client: ApiClient<APIResources>,
            ContextProvider: React.Provider<ApiClient<APIResources> | null>
        },
        i18n?: {
            locale: string,
            messages: { [id: string]: string }
        },
        otherProviders: ((children: React.ReactNode) => React.ReactNode)[] = [],
        loadingIndicator = null,
    ) {
        this.wsClient = wsClient || null;
        if (reduxStore) {
            this.reduxStore = reduxStore;
        }
        if (apiClient) {
            this.apiClient = apiClient.client;
            this.ContextProvider = apiClient.ContextProvider;
        }
        if (i18n) {
            this.i18n = i18n;
        }
        this.otherProviders = otherProviders;
        this.loadingIndicator = loadingIndicator;
    }

    protected wrapReduxProvider = (children: React.ReactNode): React.ReactNode => (
        this.reduxStore ? (
            <ReduxProvider store={this.reduxStore}>
                {children}
            </ReduxProvider>
        ) : children
    );

    protected wrapIntlProvider = (children: React.ReactNode): React.ReactNode => (
        this.i18n ? (
            <IntlProvider locale={this.i18n.locale} messages={this.i18n.messages} defaultLocale="en-GB">
                {children}
            </IntlProvider>
        ) : children
    );

    protected wrapApiClientProvider = (children: React.ReactNode): React.ReactNode => (
        this.apiClient && this.ContextProvider ? (
            <this.ContextProvider value={this.apiClient}>
                {children}
            </this.ContextProvider>
        ) : children
    );

    protected wrapWSProvider = (children: React.ReactNode): React.ReactNode => (
        this.wsClient ? (
            <WSContext.Provider value={this.wsClient}>
                {children}
            </WSContext.Provider>
        ) : children
    );

    protected wrapProviders(children: React.ReactNode): React.ReactNode {
        let result = children;

        [
            this.wrapReduxProvider,
            this.wrapIntlProvider,
            this.wrapApiClientProvider,
            this.wrapWSProvider,
            ...this.otherProviders,
        ].forEach((wrapper) => {
            result = wrapper(result);
        });

        return result;
    }

    registerAppComponent(componentName: string, importer: ComponentImporterFunction) {
        this.registeredAppComponents[componentName] = importer;
    }

    renderApp(container: HTMLElement, componentName: string, componentProps?: Record<string, unknown>) {
        if (typeof this.registeredAppComponents[componentName] === 'undefined') {
            throw new UnregisteredComponentError(`There is no registered component with name "${componentName}"`);
        }

        const App = React.lazy(this.registeredAppComponents[componentName]);

        const root = ReactDOM.createRoot(container);
        root.render((
            <React.Suspense fallback={this.loadingIndicator}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                {this.wrapProviders(<App {...componentProps} />)}
            </React.Suspense>
        ));
    }
}

export default AppRegistry;

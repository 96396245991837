import WebSocketClient, { WebSocketClientOptions } from 'speed-js-core/src/websocket/WebSocketClient';
import SessionBackend, { AuthData } from './SessionBackend';
import WSError from './WSError';

let wsClient: WebSocketClient | null = null;

class WebSocketClientProxy {
    static connect(
        url: string,
        authData?: AuthData,
        options?: WebSocketClientOptions,
    ): WebSocketClient {
        if (!wsClient) {
            const backend = new SessionBackend(authData);
            wsClient = new WebSocketClient(url, backend, window.sessionStorage, options);
        }

        return wsClient;
    }

    protected static validateClient() {
        if (!wsClient) {
            throw new WSError('Client not initialized. Call "connect" to initialize the client', 'NOT_INITIALIZED');
        }
    }

    static subscribe(groups: { [group: string]: (data: Record<string, unknown>) => void }) {
        WebSocketClientProxy.validateClient();
        wsClient?.subscribe(groups);
    }

    static unSubscribe(groups: string[] | { [group: string]: (data: Record<string, unknown>) => void }) {
        WebSocketClientProxy.validateClient();
        wsClient?.unSubscribe(groups);
    }

    static send(message: string, data: Record<string, unknown>) {
        WebSocketClientProxy.validateClient();
        wsClient?.send(message, data);
    }

    static reconnect() {
        WebSocketClientProxy.validateClient();
        wsClient?.reconnect();
    }

    static onOpen(callback: () => void) {
        WebSocketClientProxy.validateClient();
        wsClient?.onOpen(callback);
    }

    static onClose(callback: () => void) {
        WebSocketClientProxy.validateClient();
        wsClient?.onClose(callback);
    }

    static removeOnOpen(callback: () => void) {
        WebSocketClientProxy.validateClient();
        wsClient?.removeOnOpen(callback);
    }

    static removeOnClose(callback: () => void) {
        WebSocketClientProxy.validateClient();
        wsClient?.removeOnClose(callback);
    }

    static onSubscribe(group: string, callback: () => void) {
        WebSocketClientProxy.validateClient();
        wsClient?.onSubscribe(group, callback);
    }

    static getClient() {
        return wsClient;
    }
}

export default WebSocketClientProxy;

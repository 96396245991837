// eslint-disable-next-line import/no-self-import
import {
    QueryClient,
    UseQueryOptions,
    UseMutationOptions,
    DefaultOptions,
    QueryClientProvider,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React from 'react';
import { AsyncReturnType } from 'type-fest';

const queryConfig: DefaultOptions = {
    queries: {
        refetchOnWindowFocus: false,
        retry: false,
    },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export type ExtractFnReturnType<FnType extends (...args: any) => any> = Awaited<
ReturnType<FnType>
>;

export type QueryConfig<QueryFnType extends (...args: any) => any> = Omit<
UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
'queryFn' | 'queryKey'
>;

type Fn = (...args: any) => any;

export type MutationConfig<MutationFnType extends Fn> = UseMutationOptions<
ExtractFnReturnType<AsyncReturnType<MutationFnType>>,
AxiosError,
Parameters<MutationFnType>[0]
>;

export function queryClientAppRegistryProvider(children: React.ReactNode) {
    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    );
}

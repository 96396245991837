import LiveResource from 'speed-js-core/src/liveUpdate/LiveResource';
// TODO:
import { Task } from 'one-js-web/src/features/tasks/types';

class TaskResource extends LiveResource<Task> {
    basePath = 'v1/task';

    objectPath = 'v1/task?uuid={ident}';
}

export default TaskResource;

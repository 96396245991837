import Resource from 'speed-js-core/src/api/Resource';
import TaskSectionNestedResource from '../tasks/TaskSectionNestedResource';
import { Stage } from './types';

class StageResource extends Resource<Stage> {
    basePath = 'stage';

    objectPath = 'stage?uuid={ident}';

    nestedResources = {
        taskSections: TaskSectionNestedResource,
    };

    taskSections!: TaskSectionNestedResource;
}

export default StageResource;

import React from 'react';
import AuthProvider from './components/AuthProvider';

export default function appRegistryProvider(children: React.ReactNode) {
    return (
        <AuthProvider>
            {children}
        </AuthProvider>
    );
}

import NestedResource from 'speed-js-core/src/api/NestedResource';
import { TaskSection } from 'one-js-web/src/features/tasks/types';

class TaskSectionNestedResource extends NestedResource<TaskSection> {
    basePath = 'v1/stage/section?stage={parent}';

    objectPath = 'v1/stage/section?stage={parent}&uuid={ident}';
}

export default TaskSectionNestedResource;

// @flow
import type { AuthStorage } from 'speed-js-core/src/api/auth/AuthStorage';


export default class AuthLocalStorage implements AuthStorage {
    getToken = () => (
        Promise.resolve(localStorage.getItem('authToken') || null)
    );

    setToken = (token: string) => {
        localStorage.setItem('authToken', token);
        return Promise.resolve();
    };

    removeToken = () => {
        localStorage.removeItem('authToken');
        return Promise.resolve();
    };
}

// @flow

import type ApiClient from 'speed-js-core/src/api/ApiClient';
import type NestedResource from './NestedResource';

export default class Resource {
    client: ApiClient;

    basePath: string;

    objectPath: string;

    nestedResources: { [string]: Class<NestedResource> };

    constructor(client: ApiClient) {
        this.client = client;
    }

    setNestedResources() {
        if (this.nestedResources) {
            Object.keys(this.nestedResources).forEach((sr) => {
                // $FlowFixMe
                this[sr] = new this.nestedResources[sr](this.client);
            });
        }
    }

    _getBasePath = () => this.basePath;

    _getObjectPath = (id: string) => this.objectPath.replace('{ident}', id);

    // TODO: Validate object using the API schema or other resource props?

    schema(query: Object): Promise<Object> {
        return this.client._request(this._getBasePath(), 'OPTIONS', query).then((response) => response.data);
    }

    schemaMethod(id: string, method: string): Promise<Object> {
        return this.client._request(`${this._getObjectPath(id)}${method}/`, 'OPTIONS')
            .then((response) => response.data);
    }

    list(query: Object): Promise<Object> {
        return this.client._request(this._getBasePath(), 'GET', query).then((response) => response.data);
    }

    get(id: string, query: Object): Promise<Object> {
        return this.client._request(this._getObjectPath(id), 'GET', query).then((response) => response.data);
    }

    create(data: Object, files: Object): Promise<Object> {
        return this.client._request(this._getBasePath(), 'POST', data, null, files).then((response) => response.data);
    }

    update(id: string, data: Object): Promise<Object> {
        return this.client._request(this._getObjectPath(id), 'PUT', data).then((response) => response.data);
    }

    partialUpdate(id: string, data: Object): Promise<Object> {
        return this.client._request(this._getObjectPath(id), 'PATCH', data).then((response) => response.data);
    }

    delete(id: string): Promise<Object> {
        return this.client._request(this._getObjectPath(id), 'DELETE').then((response) => response.data);
    }
}

import { User } from 'one-js-web/src/types/auth';
import { Tenant } from 'one-js-web/src/types/tenants';
import React from 'react';

type AuthContextType = {
    initialised: boolean,
    currentUser: User | null,
    currentTenant: Tenant | null,
    permissions: string[],
};

const AuthContext = React.createContext<AuthContextType>({
    initialised: true,
    currentUser: null,
    currentTenant: null,
    permissions: [],
});

export default AuthContext;

import React from 'react';
import ApiClient, { HeadersType } from 'speed-js-core/src/api/ApiClient';
import NoopBackend from 'speed-js-core/src/api/auth/NoopBackend';
import { APIResources, type APIResourcesType } from 'one-js-shared';

declare global {
    interface Window {
        language?: string;
    }
}

const defaultHeaders: HeadersType = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

if (typeof window.language !== 'undefined') {
    defaultHeaders['Accept-Language'] = window.language;
}

const endpoint = process.env.REACT_APP_API_ROOT as string;

const apiClient = new ApiClient<APIResourcesType>(
    `${endpoint}/`,
    APIResources,
    NoopBackend,
    {
        authResourceName: 'auth',
        removeRefreshTokenOnFailure: false,
    },
    defaultHeaders,
    // TODO: Ready callback
);

export const APIContext = React.createContext<typeof apiClient | null>(null);

export default apiClient;

class WSError extends Error {
    code: string;

    constructor(msg: string, code: string) {
        super(msg);
        this.code = code;
    }
}

export default WSError;

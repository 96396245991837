import { useQuery } from '@tanstack/react-query';
import { axios } from 'one-js-web/src/lib/axios';
import { ExtractFnReturnType, QueryConfig } from 'one-js-web/src/lib/react-query';
import { Tenant } from 'one-js-web/src/types/tenants';

export const getCurrentTenant = (): Promise<Tenant> => axios.get('/v1/auth/tenants/current');

type QueryFnType = typeof getCurrentTenant;

type UseGetCurrentTenantOptions = {
    config?: QueryConfig<QueryFnType>,
};

export const useGetCurrentTenant = (
    { config }: UseGetCurrentTenantOptions,
) => useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['currentTenant'],
    queryFn: () => getCurrentTenant(),
    staleTime: 1 * 60 * 60 * 1000, // 1 Hou
});

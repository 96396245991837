import { useQuery } from '@tanstack/react-query';
import { axios } from 'one-js-web/src/lib/axios';
import { ExtractFnReturnType, QueryConfig } from 'one-js-web/src/lib/react-query';
import { User } from 'one-js-web/src/types/auth';

export const getCurrentUser = (): Promise<User> => axios.get('/v1/auth/users/current');

type QueryFnType = typeof getCurrentUser;

type UseGetCurrentUserOptions = {
    config?: QueryConfig<QueryFnType>,
};

export const useGetCurrentUser = (
    { config }: UseGetCurrentUserOptions,
) => useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['currentUser'],
    queryFn: () => getCurrentUser(),
    staleTime: 1 * 60 * 60 * 1000, // 1 Hour
});

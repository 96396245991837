// @flow

import type { AuthBackend, AuthSettings } from 'speed-js-core/src/api/auth/AuthBackend';
import typeof Resource from 'speed-js-core/src/api/Resource';
import JWTBackend from 'speed-js-core/src/api/auth/JWTBackend';
import AuthLocalStorage from 'speed-js-core/src/api/auth/AuthLocalStorage';
import ApiClientError, { ERR_INVALID_EVENT, ERR_AUTH_BACKEND_NOT_INITIALIZED } from 'speed-js-core/src/api/errors';
import { HTTP_204_NO_CONTENT } from 'speed-js-core/src/api/constants';

export default class ApiClient {
    +_subscriptions = {
        authorized: [],
        request: [],
        success: [],
        failure: [],
    };

    _endpoint: string;

    _baseHeaders: Object;

    auth: Object;

    +resources: Object = {};

    ready: boolean = false;

    authorized: boolean = false;

    defaultCredentials: 'omit' | 'same-origin' | 'include' = 'same-origin';

    constructor(
        endpoint: string,
        resources: {[string]: Class<Resource>} = {},
        AuthBackendClass: Class<AuthBackend> = JWTBackend,
        authSettings: AuthSettings = {
            AuthStorageClass: AuthLocalStorage,
            authResourceName: 'auth',
        },
        headers: Object = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        readyCallback?: (client: ApiClient, error?: Object) => {},
        defaultCredentials: 'omit' | 'same-origin' | 'include' = 'same-origin',
    ) {
        this._endpoint = endpoint;
        this._baseHeaders = headers;
        this.defaultCredentials = defaultCredentials;

        this.registerResources(resources);

        this.auth = new AuthBackendClass(this, authSettings);
        this.auth.init().then(([authorized, error]) => {
            this.ready = true;
            this.authorized = authorized;
            if (readyCallback) {
                readyCallback(this, error);
            }
        });
    }

    registerResources(resources: {[string]: Class<Resource>}) {
        Object.entries(resources).forEach(([name, Klass]: [string, any]) => {
            this.resources[name] = new Klass(this);
            this.resources[name].setNestedResources();
        });
    }

    getBaseHeaders(): Object {
        return this._baseHeaders;
    }

    setBaseHeaders(headers: Object) {
        this._baseHeaders = headers;
    }

    _combineHeaders = async (headers: Object): Object => {
        const authHeaders = await this.auth.getHeaders();
        return {
            ...this._baseHeaders,
            ...authHeaders,
            ...headers,
        };
    };

    _getRequestEndpoint = (path: string, query: Object = {}): string => {
        const endpoint = new URL(this._endpoint + path);
        Object.entries(query).forEach(([key, value]: [string, any]) => {
            if (value !== null) {
                if (Array.isArray(value)) {
                    value.forEach((subValue) => endpoint.searchParams.append(key, subValue));
                } else endpoint.searchParams.append(key, value);
            }
        });

        return endpoint.toString();
    };

    // eslint-disable-next-line class-methods-use-this
    _generateBody = (data: {files?: Object, ...} = {}, headers: Object = {}): string | FormData => {
        // If the content type is set to 'multipart/form-data' or there are files that need to be uploaded we need to
        // send the body as a FormData object
        if ((headers && headers['Content-Type'] === 'multipart/form-data') || data.files) {
            if (headers['Content-Type']) {
                delete headers['Content-Type'];
            }

            const { files, ...rest } = data;
            const formData = new FormData();
            Object.entries(rest).forEach(([name, value]: [string, any]) => {
                if (Array.isArray(value)) {
                    value.forEach((v) => {
                        formData.append(name, v);
                    });
                } else if (typeof value === 'object' && value !== null) {
                    formData.append(name, JSON.stringify(value));
                } else if (value !== null) {
                    formData.append(name, value);
                }
            });
            if (files) {
                Object.entries(files).forEach(([name, _files]: [string, any]) => {
                    if (_files) {
                        _files.forEach((f) => {
                            formData.append(name, f, f.name);
                        });
                    }
                });
            }

            return formData;
        }

        return JSON.stringify(data);
    };

    _request = (
        path: string, method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'HEAD' = 'GET',
        data?: Object = {}, headers?: Object = {},
        _credentials: 'omit' | 'same-origin' | 'include' | typeof undefined = undefined,
        force: boolean = false,
        signal = undefined,
    ): Promise<{
        ok: boolean,
        status: number,
        statusText: string,
        headers: Object,
        data: Object,
    }> => (
        this.request(path, method, data, headers, _credentials, force, signal)
    );

    request = (
        path: string, method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'HEAD' = 'GET',
        data?: Object = {}, headers?: Object = {},
        _credentials: 'omit' | 'same-origin' | 'include' | typeof undefined = undefined,
        force: boolean = false,
        signal = undefined,
    ): Promise<{
        ok: boolean,
        status: number,
        statusText: string,
        headers: Object,
        data: Object,
    }> => {
        const credentials = _credentials || this.defaultCredentials;

        const performRequest = async () => {
            const endpoint = this._getRequestEndpoint(path, method === 'GET' || method === 'OPTIONS' ? data : {});
            const _headers = await this._combineHeaders(headers);
            const body = method !== 'GET' && method !== 'OPTIONS' ? this._generateBody(data, _headers) : undefined;

            this._subscriptions.request.forEach((c) => c(endpoint, method, _headers, body));
            return fetch(
                endpoint,
                {
                    method,
                    // cache: 'no-cache',
                    credentials,
                    headers: _headers,
                    body,
                    signal,
                },
            ).then((response: Response) => {
                const processRequest = (responseData: Response) => {
                    const result = {
                        ok: response.ok,
                        status: response.status,
                        statusText: response.statusText,
                        headers: response.headers,
                        data: responseData,
                    };

                    if (response.ok) {
                        this._subscriptions.success.forEach((c) => c(result, endpoint, method, headers, body));
                    } else {
                        return Promise.reject(result);
                    }

                    return result;
                };

                if (response.status === HTTP_204_NO_CONTENT) {
                    // $FlowFixMe
                    return Promise.resolve({}).then(processRequest);
                }

                return response.json().then(processRequest);
            }).catch((error) => {
                this._subscriptions.failure.forEach((c) => c(error, endpoint, method, headers, body));

                return Promise.reject(error);
            });
        };

        if (this.ready || force) {
            return performRequest();
        }

        // Wait 0.1 second, max 1000 times, to check if we the client is ready (auth backend is initialized)
        return new Promise((resolve, reject) => {
            let retries = 1000;

            const wait = () => {
                if (retries > 0) {
                    setTimeout(() => {
                        if (this.ready) {
                            performRequest()
                                .then(resolve)
                                .catch(reject);
                        } else {
                            retries -= 1;
                            wait();
                        }
                    }, 100);
                } else {
                    throw new ApiClientError(
                        'Auth backend not initialized',
                        ERR_AUTH_BACKEND_NOT_INITIALIZED,
                    );
                }
            };
            wait();
        });
    };

    authorize(...args: Array<mixed>): Object {
        return this.auth.authorize(...args).then((result) => {
            this.authorized = true;
            this._subscriptions.authorized.forEach((c) => c(result));
            return result;
        });
    }

    deauthorize(): Promise<> {
        return this.auth.deauthorize().then(() => {
            this.authorized = false;
        });
    }

    /**
     * Subscribe to 1 of the 3 request stages - request, success, failed.
     * These callbacks will be executed on each request made.
     *
     * @param {string} event - The event, must be one of: request, success, failure
     * @param {function} callback - The callback to be executed when the event is triggered
     * @returns {undefined};
     */
    subscribe(
        event: string,
        callback: (...args: Array<any>) => (
            Promise<void>
        ),
    ) {
        if (typeof this._subscriptions[event] === 'undefined') {
            throw new ApiClientError(
                ERR_INVALID_EVENT,
                `Invalid event "${event}", must be one of ${Object.keys(this._subscriptions).join(', ')}`,
            );
        }
        this._subscriptions[event].push(callback);
    }

    /**
     * Unsubscribe from 1 of the 3 request stages - request, success, failed.
     *
     * @param {string} event - The event, must be one of: request, success, failure
     * @param {function} callback - The previously subscribed callback to be executed when the event is triggered
     * @returns {undefined};
     */
    unsubscribe(
        event: string,
        callback: (...args: Array<any>) => (
            Promise<void>
        ),
    ) {
        if (typeof this._subscriptions[event] === 'undefined') {
            throw new ApiClientError(
                ERR_INVALID_EVENT,
                `Invalid event "${event}", must be one of ${Object.keys(this._subscriptions).join(', ')}`,
            );
        }

        const index = this._subscriptions[event].indexOf(callback);
        if (index > -1) {
            this._subscriptions[event].splice(index, 1);
        }
    }
}

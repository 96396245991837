import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TitleState {
    title: string | null;
}

const initialState: TitleState = {
    title: null,
};

const titleSlice = createSlice({
    name: 'title',
    initialState,
    reducers: {
        setTitle: (state, action: PayloadAction<string>) => {
            state.title = action.payload;
        },
    },
});

export const { setTitle } = titleSlice.actions;
export const appTitle = (state: { title: TitleState }) => state.title;
export const titleReducer = titleSlice.reducer;
